html,
body {
  padding: 0;
  margin: 0;
  font-family:
    'Lato',
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ul,
ol {
  padding-left: 22px;
  line-height: 32px;
}

@font-face {
  font-family: 'Lato';
  src: local('Font Name'), local('FontName'), url('assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Font Name'), local('FontName'), url('assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Font Name'), local('FontName'), url('assets/fonts/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  src: url(assets/fonts/MaterialSymbolsOutlined.woff2) format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
