@font-face {
    font-family: 'Source Sans Pro';
    src: url('./SourceSansPro-Regular.80c998aa03640281d556814a14e1d9c8.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'GoodOT';
    src: url('./GoodOT-Regular.337482c94ac23b0a27d9a4ae9fc3d9f9.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'GoodOT';
    src: url('./GoodOT-Medium.c289010ebc88b96b0ca162bfd70ce866.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'GoodOTNarrow';
    src: url('./GoodOTNarrow-Ultra.a656c4692b9368d3c6ac04145f753451.woff2') format('woff2');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}
